/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// @mui material components
import Card from '@mui/material/Card'
// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import PlansModal from './components/Modals/Plans'
import MUIDataTable from 'mui-datatables'
import { Grid } from '@mui/material'

function Accounts({ PlansTableData, data, handleChange }) {
  const columns = [
    {
      name: 'Name',
      options: {
        filter: false,
      },
    },
    {
      name: 'Price',
      options: {
        filter: false,
      },
    },
    {
      name: 'Plan Id',
      options: {
        filter: false,
      },
    },
    {
      name: 'Action',
      options: {
        filter: false,
      },
    },
  ]

  const options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: 'none',
    filterType: 'multiselect',
    responsive: 'standard',
  }

  return (
    <Card id="plan-configuration">
      <MDBox p={3} lineHeight={1}>
        <MDBox mb={1}>
          <MDTypography variant="h5">Plan Configuration</MDTypography>
        </MDBox>
        <MDTypography variant="button" color="text">
          Here you can setup and manage your payment plans configurations.
        </MDTypography>
      </MDBox>
      <MDBox pt={2} pb={3} px={3}>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: 'flex-start', sm: 'center' }}
          flexDirection={{ xs: 'column', sm: 'row' }}
        >
          <Grid container>
            <Grid item xs={12} mb={3} display={'flex'} justifyContent={'flex-end'}>
              <PlansModal onLoad={handleChange} data={data} />
            </Grid>

            <Grid item xs={12}>
              <MUIDataTable
                title={'Plans'}
                data={PlansTableData}
                columns={columns}
                options={options}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  )
}

export default Accounts
