/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import { useAppServices } from 'hook/services'
import { useParams } from 'react-router-dom'

function ChangePassword({ handleChange, data }) {
  const Service = useAppServices()
  const { id } = useParams()
  const handleSubmit = async (e) => {
    e.preventDefault()
    const payload = {
      client_id: e.target.client_id.value,
      client_secret: e.target.client_secret.value,
      sso: e.target.sso.value,
      _id: id,
    }
    const { response } = await Service.snapshot_onboarding.update({ payload })
    if (response) {
      handleChange()
    }
  }

  return (
    <Card id="auth-configuration">
      <MDBox p={3}>
        <MDTypography variant="h5">Auth Configuration</MDTypography>
      </MDBox>
      <MDBox component="form" onSubmit={handleSubmit} role="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDTypography variant="button" display="block" gutterBottom>
              Client ID
            </MDTypography>
            <MDInput defaultValue={data?.client_id} fullWidth label="Client ID" name="client_id" />
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="button" display="block" gutterBottom>
              Client Secret
            </MDTypography>
            <MDInput
              fullWidth
              label="Client Secret"
              name="client_secret"
              defaultValue={data?.client_secret}
            />
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="button" display="block" gutterBottom>
              SSO Key
            </MDTypography>
            <MDInput fullWidth label="SSO Key" name="sso" defaultValue={data?.sso} />
          </Grid>
        </Grid>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          flexWrap="wrap"
          mt={3}
        >
          <MDBox ml="auto">
            <MDButton variant="gradient" color="dark" size="small" type="submit">
              update
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  )
}

export default ChangePassword
