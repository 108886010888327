/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import MDBadge from 'components/MDBadge'

function Authentication({ data }) {
  const copyToClipboard = (url) => {
    const text = url

    // Create a temporary textarea element to hold the text
    const textarea = document.createElement('textarea')
    textarea.value = text
    textarea.setAttribute('readonly', '')
    textarea.style.position = 'absolute'
    textarea.style.left = '-9999px'

    // Append the textarea to the body
    document.body.appendChild(textarea)

    // Select the text inside the textarea
    textarea.select()

    // Execute the copy command
    document.execCommand('copy')
    document.body.removeChild(textarea)
  }
  return (
    <Card id="app-setup" sx={{ overflow: 'visible' }}>
      <MDBox p={3} lineHeight={1}>
        <MDBox mb={1}>
          <MDTypography variant="h5">Marketplace App Setup</MDTypography>
        </MDBox>
        <MDTypography variant="button" color="text">
          Following configurations Needs to added in your marketplace app
        </MDTypography>
      </MDBox>
      <MDBox p={3}>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: 'flex-start', sm: 'center' }}
          flexDirection={{ xs: 'column', sm: 'row' }}
        >
          <MDTypography variant="body2" color="text">
            Custom Page Url
          </MDTypography>
          <MDBox
            display="flex"
            alignItems={{ xs: 'flex-start', sm: 'center' }}
            flexDirection={{ xs: 'column', sm: 'row' }}
          >
            <MDButton
              variant="outlined"
              color="dark"
              size="small"
              onClick={() =>
                copyToClipboard(
                  `https://snapshotonboarding.levelupmarketplace.io/${data?.app_id}/onboarding/redirect`
                )
              }
            >
              Copy
            </MDButton>
          </MDBox>
        </MDBox>
        <Divider />
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: 'flex-start', sm: 'center' }}
          flexDirection={{ xs: 'column', sm: 'row' }}
        >
          <MDTypography variant="body2" color="text">
            Webhook Url
          </MDTypography>
          <MDBox
            display="flex"
            alignItems={{ xs: 'flex-start', sm: 'center' }}
            flexDirection={{ xs: 'column', sm: 'row' }}
          >
            <MDButton
              variant="outlined"
              color="dark"
              size="small"
              onClick={() =>
                copyToClipboard(
                  `https://marketplace.ghl.store/api/v1/webhook/snapshot_onboarding_installevent`
                )
              }
            >
              Copy
            </MDButton>
          </MDBox>
        </MDBox>
        <Divider />
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: 'flex-start', sm: 'center' }}
          flexDirection={{ xs: 'column', sm: 'row' }}
        >
          <MDTypography variant="body2" color="text">
            Redirect Url
          </MDTypography>
          <MDBox
            display="flex"
            alignItems={{ xs: 'flex-start', sm: 'center' }}
            flexDirection={{ xs: 'column', sm: 'row' }}
          >
            <MDButton
              variant="outlined"
              color="dark"
              size="small"
              onClick={() =>
                copyToClipboard(
                  `https://snapshotonboarding.levelupmarketplace.io/integrations/auth/snapshot_onboarding/${data.app_id}`
                )
              }
            >
              Copy
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  )
}

export default Authentication
