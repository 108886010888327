import Loader from 'examples/Loader'
import { useAppServices } from 'hook/services'
import ssoHandler from 'layouts/Pages/ssoHandler'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const index = () => {
  const { app_id } = useParams()
  const { SSO, checkSSO } = ssoHandler()
  const [ssodata, setssodata] = useState({})
  const [appData, setAppData] = useState({})
  const [loader, setloader] = useState(true)
  const AppService = useAppServices()
  const iframeStyles = {
    width: '100%',
    height: 'calc(100vh - 70px)',
    border: 'none',
  }
  const AppsData = async () => {
    const { response } = await AppService.snapshot_onboarding.filter({
      query: `app_id=${app_id}`,
    })
    console.log(response, 'AppsData')
    if (response) {
      setAppData(response.data)
      checkSSO(response.data)
    }
  }
  useEffect(() => {
    AppsData()
  }, [])
  useEffect(() => {
    if (SSO != '' && SSO != undefined) {
      var data = JSON.parse(SSO)
      setssodata(data)
      setloader(false)
    }
    console.log(SSO, 'SSOSSO')
  }, [SSO])

  return (
    <div>
      {loader ? (
        <Loader />
      ) : (
        <>
          {appData?.agency_type == true && appData?.sub_account_type == false && (
            <iframe
              src={`${appData?.agency_page}?company_id=${ssodata?.companyId}`}
              style={iframeStyles}
            ></iframe>
          )}
          {appData?.agency_type == false && appData?.sub_account_type == true && (
            <iframe
              src={`${appData?.location_page}?location_id=${ssodata?.activeLocation}`}
              style={iframeStyles}
            ></iframe>
          )}
          {appData?.agency_type == true && appData?.sub_account_type == true && (
            <>
              {ssodata.activeLocation ? (
                <iframe
                  src={`${appData?.location_page}?location_id=${ssodata?.activeLocation}`}
                  style={iframeStyles}
                ></iframe>
              ) : (
                <iframe
                  src={`${appData?.agency_page}?company_id=${ssodata?.companyId}`}
                  style={iframeStyles}
                ></iframe>
              )}
            </>
          )}
        </>
      )}
    </div>
  )
}

export default index
