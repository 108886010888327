// import Item from "antd/lib/list/Item";
// import { useAgencyInfo } from "context/agency";
// import { useAppServices } from "hook/services";
// import React from "react";
import { useEffect, useState } from 'react';
// import { useState } from "react";
import crypto from 'crypto-js';
// import 'crypto-js/addon/pad-iso10126';
// import 'crypto-js/enc-utf8';

const ssoHandler = () => {
    const [ssodata, setssodata] = useState('');
    const decript_data = (payload, app) => {
        console.log(payload, app, 'payloadpayloadpayloadpayload');
        const ciphertext = crypto.AES.decrypt(payload, app.key).toString(crypto.enc.Utf8);
        setssodata(ciphertext);
        console.log(ciphertext, 'decript_data');
    };
    const checkSSO = (app) => {
        // console.log(app, 'appappapp')
        decript_data('U2FsdGVkX19jIkzumPlCydFaaaNCl3QD8HYPqOmgM8ph6mUdtaE30q4+wIhz9KaTiqox4nIuYA8UlNwCZb+uLg9S+XtcRjav6AwJKSR2CrqnQD1H/6Ox8IU8fpvDlS0ZEjKIQ0bFDTlWwhY2Vyyf7Ab3xhTjwqKo1yvsqEssFYZZEOIaLGq6ohM7W7mn2jhzZ7/mjaf/uA35JwFBtyDxE7aI56brAGcdXzMq55Wwfbro3+vWPI9XeBlqlAGv2z7Q', { key: "32ee586f-a904-49d8-8b77-63cc417cf828" });


        const key = new Promise((resolve) => {
            window.parent.postMessage({ message: 'REQUEST_USER_DATA' }, '*');
            const sso = {
                app_id: app.app_id,
                key: app.sso,
            };
            console.log(sso, 'sso payload');
            console.log(window.parent.postMessage({ message: 'REQUEST_USER_DATA' }, '*'), 'test data');
            const temp = window.addEventListener('message', ({ data }) => {
                console.log(data, 'datadata')
                if (data.message === 'REQUEST_USER_DATA_RESPONSE') {
                    const sso = {
                        app_id: app.app_id,
                        key: app.sso,
                    };
                    console.log(data.payload, sso, 'sso payload');
                    decript_data(data.payload, sso);
                } else {
                }
                // console.log(temp, 'temptemptemptemptemptemp')
            });
        });
    };
    return {
        SSO: ssodata,
        checkSSO: checkSSO,
    };
};
export default ssoHandler;
