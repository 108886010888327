import MDButton from 'components/MDButton'
import AddBoxIcon from '@mui/icons-material/AddBox'
import React, { useEffect, useState } from 'react'
import MDModal from 'components/MDModal'
import MDInput from 'components/MDInput'
import Grid from '@mui/material/Grid'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { useAppServices } from 'hook/services'
import { useAgencyInfo } from 'context/agency'
import { Link, useParams } from 'react-router-dom'
import { Button, Checkbox, FormControlLabel, Icon, TextField, Typography } from '@mui/material'
import LaunchIcon from '@mui/icons-material/Launch'
const CreateModal = (props) => {
  const Service = useAppServices()
  const { location_id, app_id } = useParams()
  const [openAddProduct, setOpenAddProduct] = useState(false)
  function AddProduct({ open, onClose }) {
    const [processing, setProcessing] = useState(false)

    const handleSubmit = async (e) => {
      e.preventDefault()
      setProcessing(true)
      const payload = {
        app_name: e.target.app_name.value,
        app_id: e.target.app_id.value,
        agency_type: e.target.agency_type.checked,
        sub_account_type: e.target.sub_account_type.checked,
        main_app_id: app_id,
        location_id: location_id,
      }
      const { response } = await Service.snapshot_onboarding.create({ payload })
      if (response) {
        setOpenAddProduct(false)
        setProcessing(false)
        props.handleChange()
      } else {
        setProcessing(false)
      }
    }
    return (
      <MDModal open={open} onClose={onClose}>
        <MDBox>
          <MDTypography component="h1" mb={3} variant="h5">
            App
          </MDTypography>

          <MDBox component="form" onSubmit={handleSubmit} role="form" sx={{ width: 335 }}>
            <Grid item md={12}>
              <MDBox mb={1}>
                <MDTypography variant="button" display="block" gutterBottom>
                  App Name
                </MDTypography>
                <MDInput
                  defaultValue=""
                  id="bootstrap-input"
                  fullWidth
                  placeholder="App Name"
                  name="app_name"
                />
              </MDBox>
            </Grid>
            <Grid item md={12}>
              <MDBox mb={1}>
                <MDTypography variant="button" display="block" gutterBottom>
                  App ID
                </MDTypography>
                <MDInput
                  defaultValue=""
                  id="bootstrap-input"
                  fullWidth
                  placeholder="App ID"
                  name="app_id"
                />
              </MDBox>
            </Grid>
            {/* <Grid item md={12}>
              <MDBox mb={1}>
                <MDTypography variant="button" display="block" gutterBottom>
                  Client ID
                </MDTypography>
                <MDInput
                  defaultValue=""
                  id="bootstrap-input"
                  fullWidth
                  placeholder="Client ID"
                  name="client_id"
                />
              </MDBox>
            </Grid> */}
            {/* <Grid item md={12}>
              <MDBox mb={1}>
                <MDTypography variant="button" display="block" gutterBottom>
                  Client Secret
                </MDTypography>
                <MDInput
                  defaultValue=""
                  id="bootstrap-input"
                  fullWidth
                  placeholder="Client Secret"
                  name="client_secret"
                />
              </MDBox>
            </Grid> */}
            {/* <Grid item md={12}>
              <MDBox mb={1}>
                <MDTypography variant="button" display="block" gutterBottom>
                  SSO Key
                </MDTypography>
                <MDInput
                  defaultValue=""
                  id="bootstrap-input"
                  fullWidth
                  placeholder="SSO Key"
                  name="sso"
                />
              </MDBox>
            </Grid> */}
            {/* <Grid item md={12}>
              <MDBox mb={1}>
                <MDTypography variant="button" display="block" gutterBottom>
                  Location Id Custom Field Key
                </MDTypography>
                <MDInput
                  defaultValue=""
                  id="bootstrap-input"
                  fullWidth
                  placeholder="Location Id Custom Field Key"
                  name="query_key"
                />
              </MDBox>
            </Grid> */}
            {/* <Grid item md={12}>
              <MDBox mb={1}>
                <MDTypography variant="button" display="block" gutterBottom>
                  Form Url
                </MDTypography>
                <MDInput
                  defaultValue=""
                  id="bootstrap-input"
                  fullWidth
                  placeholder="Form Url"
                  name="form_url"
                />
              </MDBox>
            </Grid>
            <Grid item md={12}>
              <MDBox mb={1}>
                <MDTypography variant="button" display="block" gutterBottom>
                  Forwarding Url
                </MDTypography>
                <MDInput
                  defaultValue=""
                  id="bootstrap-input"
                  fullWidth
                  placeholder="Forwarding Url"
                  name="forward_url"
                />
              </MDBox>
            </Grid> */}

            {/* <Grid item md={12}>
              <MDBox mb={1}>
                <MDTypography variant="button" display="block" gutterBottom>
                  App Name
                </MDTypography>
                <TextField
                  defaultValue=""
                  id="app-name"
                  fullWidth
                  placeholder="App Name"
                  name="app_name"
                  variant="outlined"
                />
              </MDBox>
            </Grid>

            <Grid item md={12}>
              <MDBox mb={1}>
                <MDTypography variant="button" display="block" gutterBottom>
                  App ID
                </MDTypography>
                <TextField
                  defaultValue=""
                  id="app-id"
                  fullWidth
                  placeholder="App ID"
                  name="app_id"
                  variant="outlined"
                />
              </MDBox>
            </Grid> */}

            <Grid item md={12} mt={3}>
              <MDBox mb={1}>
                <MDTypography variant="button" display="block" gutterBottom>
                  Distribution Type
                </MDTypography>
              </MDBox>
            </Grid>

            <Grid container item md={10}>
              <Grid item xs={5}>
                <MDBox mb={0.5}>
                  <FormControlLabel control={<Checkbox name="agency_type" />} label="Agency" />
                </MDBox>
              </Grid>

              <Grid item xs={6}>
                <MDBox mb={0.5}>
                  {' '}
                  <FormControlLabel
                    control={<Checkbox name="sub_account_type" />}
                    label="Sub-Account"
                  />
                </MDBox>
              </Grid>
            </Grid>

            <MDBox>
              <MDButton
                variant="gradient"
                color="info"
                type="submit"
                disabled={processing}
                loading={processing}
                sx={{ mt: 4, mb: 1 }}
                fullWidth
              >
                Add
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDModal>
    )
  }
  const closeAddProduct = (subscription) => {
    // if (subscription?._id)
    setOpenAddProduct(false)
  }
  return (
    <>
      <MDButton variant="gradient" color="info" onClick={() => setOpenAddProduct(true)}>
        <Icon>add</Icon>&nbsp; Add New
      </MDButton>
      <AddProduct open={openAddProduct} onClose={closeAddProduct} />
    </>
  )
}

export default CreateModal
