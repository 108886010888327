import { Environment } from '../enums'

const env = {
  API_URL: 'https://api.alltheapps.io/api',
  // API_URL: 'http://localhost:8082/api',
  GHL: {
    CALENDER_MAP_CLIENT_ID: '6645dcb9e68c987211dc0b55-lw9ckij9',
    CALENDER_MAP_CLIENT_SECRET: '8a59557e-fcf8-4113-bada-c7a73f4359d1',
    CALENDER_MAP_SCOPE:
      'calendars.write calendars.readonly calendars/events.readonly calendars/events.write calendars/groups.readonly calendars/groups.write calendars/resources.readonly calendars/resources.write locations.readonly locations.write users.readonly contacts.readonly',
    CLIENT_ID: '6644d21f4c8f5680097182f5-lw7ywt6q',
    CLIENT_SECRET: '3d41e631-96fc-4358-a8ac-8b1941306cd5',
    SCOPE:
      'locations.readonly locations/customFields.readonly locations/customFields.write locations/customValues.readonly locations/customValues.write',
    REDIRECT: {
      ASSOCIATE: 'https://snapshotonboarding.levelupmarketplace.io/integrations/auth',
      CALENDER_MAP_ASSOCIATE:
        'https://calendarmaps.levelupmarketplace.io/integrations/auth/calender',
    },
  },
}

// if(process.env.REACT_APP_ENV === Environment.DEVELOPMENT){
// }

// if (process.env.REACT_APP_ENV === Environment.STAGING) {
//   env.API_URL = 'https://fayepooley.com/api'
//   env.GHL.REDIRECT.ASSOCIATE = 'https://fayepooley.com/integrations/auth'
// }

// if(process.env.REACT_APP_ENV === Environment.PRODUCTION){

// }

export default env
