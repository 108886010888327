/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import { useAppServices } from 'hook/services'
import { useParams } from 'react-router-dom'

function AppConfiguration({ data, handleChange }) {
  const Service = useAppServices()
  const { id } = useParams()

  const handleSubmit = async (e) => {
    e.preventDefault()
    const payload = {
      agency_page: data?.agency_type == true ? e.target.agency_page.value : null,
      location_page: data?.sub_account_type == true ? e.target.location_page.value : null,
      forward_url: e.target.forward_url.value,
      _id: id,
    }
    const { response } = await Service.snapshot_onboarding.update({ payload })
    if (response) {
      handleChange()
    }
  }

  return (
    <Card id="app-configuration">
      <MDBox p={3}>
        <MDTypography variant="h5">App Configuration</MDTypography>
      </MDBox>
      <MDBox component="form" onSubmit={handleSubmit} role="form" pb={3} px={3}>
        <Grid container spacing={3}>
          {data?.agency_type == true && (
            <Grid item xs={12}>
              <MDTypography variant="button" display="block" gutterBottom>
                Agency Custom Page URL
              </MDTypography>
              <MDInput
                fullWidth
                label="Agency Page URL"
                name="agency_page"
                defaultValue={data?.agency_page}
              />
            </Grid>
          )}
          {data?.sub_account_type == true && (
            <Grid item xs={12}>
              <MDTypography variant="button" display="block" gutterBottom>
                Location Custom Page URL
              </MDTypography>
              <MDInput
                fullWidth
                label="Location Custom Page URL"
                name="location_page"
                defaultValue={data?.location_page}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <MDTypography variant="button" display="block" gutterBottom>
              Farwarding URL
            </MDTypography>
            <MDInput
              fullWidth
              label="Confirm New Password"
              name="forward_url"
              defaultValue={data?.forward_url}
            />
          </Grid>
        </Grid>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          flexWrap="wrap"
          mt={3}
        >
          <MDBox ml="auto">
            <MDButton variant="gradient" color="dark" size="small" type="submit">
              update
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  )
}

export default AppConfiguration
