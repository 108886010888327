import { useNavigate } from 'react-router-dom';
import './style.css';
import logo from '../../../assets/Logo/levelUpLogo.png';

const MassagePage = (props) => {
    console.log(props, 'Error');
    const { Error } = props;

    const navigate = useNavigate();
    const handleALlTheAppsRedirect = () => {
        const url = `https://app.gohighlevel.com/integration/670ce982292541018a34fb9a`;

        window.open(url, '_blank');
    };
    return (
        <div className="flex w-full flex-col">
            {/* <div className="p-5 w-full flex justify-center items-center bg-dark shadow-lg">
                <img src={logo} width={150} alt="logo" />
            </div> */}

            <div className="flex flex-col gap-5 w-full items-center justify-center bg-gradient h-100vh">
                <img src={logo} width={300} alt="logo" />
                <div>
                    {Error ? (
                        <h1 className="text-4xl font-black text-center uppercase">
                            App <span className="text-danger drop-shadow-lg">Not Installed </span>Successfully
                        </h1>
                    ) : (
                        <h1 className="text-4xl font-black text-center uppercase">
                            App <span className="text-[#a062e2] drop-shadow-lg"> Successfully</span> Installed
                        </h1>
                    )}
                    <p className="text-lg font-semibold text-center capitalize">
                        {Error ? 'An issue has occurred during integration. Please try again later.' : 'You can now use the app in your account.'}
                    </p>
                </div>
                <div>
                    {Error ? (
                        <button className="bg-danger text-white px-5 py-2 rounded-md shadow-lg" onClick={() => navigate(-2)}>
                            Go to Back
                        </button>
                    ) : (
                        <button className="bg-primary-button text-white px-5 py-2 rounded-md shadow-lg">Go to Dashboard</button>
                    )}
                    <button className="bg-primary-button text-white ml-2 px-5 py-2 rounded-md shadow-lg" onClick={handleALlTheAppsRedirect}>
                        Install All The Apps
                    </button>
                </div>
            </div>
        </div>
    );
};

export default MassagePage;
