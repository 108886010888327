import MDButton from 'components/MDButton'
import Loader from 'examples/Loader'
import { useAppServices } from 'hook/services'
import MUIDataTable$1 from 'mui-datatables'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

function Users() {
  const Service = useAppServices()
  const { location_id, app_id } = useParams()
  const [users, setUsers] = useState([])
  const [Conjo, setConjo] = useState([])
  const [data, setdata] = useState([])
  const [agency, setAency] = useState({})
  const [processing, setProcessing] = useState(true)

  const options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: 'none',
    filterType: 'multiselect',
    responsive: 'standard',
  }
  const columns = [
    {
      name: 'Name',
      options: {
        filter: false,
      },
    },
    {
      name: 'Email',
      options: {
        filter: false,
      },
    },
    {
      name: 'First Name',
      options: {
        filter: false,
      },
    },
    {
      name: 'Last Name',
      options: {
        filter: false,
      },
    },
    // {
    //     name: 'Action',
    //     options: {
    //         filter: false,
    //     },
    // },
  ]

  const getData = async () => {
    const { response } = await Service.snapshot_onboarding.get_app_users({
      query: `location_id=${location_id}`,
    })
    if (response) {
      var tabel_data = []
      response.data.forEach((element) => {
        const temp = [element.Name, element.email, element.Firstname, element.Lastname]
        tabel_data.push(temp)
      })
      setConjo(tabel_data)
      setAency(response.agency)
      setProcessing(false)
    } else {
      setProcessing(false)
    }
  }

  useEffect(async () => {
    getData()
  }, [])

  return (
    <>
      {processing ? (
        <Loader />
      ) : (
        <>
          <h2 className="mb-5">Users</h2>
          <MUIDataTable$1 title={'Users'} data={Conjo} columns={columns} options={options} />
        </>
      )}
    </>
  )
}

export default Users
