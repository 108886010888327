import React from 'react'
import { Card, CardContent, Grid, Typography } from '@mui/material'
import Loader from 'examples/Loader'

import { useEffect, useState } from 'react'
import { useAppServices } from 'hook/services'
import { useNavigate, useParams } from 'react-router-dom'
import Settings from './components/pages/accounts/settings'
function AppDetails() {
    const { location_id, app_id, id } = useParams()
    const Service = useAppServices()
    const [processing, setProcessing] = useState(true)

    let [cardData, setCardData] = useState(
        [
            { title: 'App Name', value: "" },
            { title: 'Total Users', value: 0 },
            { title: 'Total Locations', value: 0 },
            { title: 'Total Companies', value: 0 },
        ]
    )

    const getData = async () => {
        const { response } = await Service.snapshot_onboarding.get_app_dashboard({
            query: `location_id=${location_id}&_id=${id}`,
        })
        if (response) {
            console.log(response, 'response')

            setCardData(
                [
                    { title: 'App Name', value: response.data?.app_name },
                    { title: 'Total Users', value: response.users.length },
                    { title: 'Total Locations', value: response.locations.length },
                    { title: 'Total Companies', value: response.companies.length },
                ]
            )


            setProcessing(false)

        }
    }

    const onLoad = () => {
        getData()
    }
    useEffect(async () => {
        onLoad();
    }, [])

    return (
        <>
            {processing ? (
                <Loader />
            ) : (
                <>
                    <Settings />
                </>
            )}
        </>
    )
}

export default AppDetails
