import React from 'react'
import Header from './Header'

const index = () => {
  return (
    <div>
      <Header />
    </div>
  )
}

export default index
