/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from '@mui/material/Card'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import { useAppServices } from 'hook/services'
import { useNavigate, useParams } from 'react-router-dom'

function DeleteAccount() {
  const Service = useAppServices()
  const { id, location_id, app_id } = useParams()
  const navigate = useNavigate()
  const hadleDeleteApp = async () => {
    const { response } = await Service.snapshot_onboarding.delete({
      query: `_id=${id}`,
    })
    if (response) {
      navigate(`/${location_id}/${app_id}/onboarding`)
    }
  }
  return (
    <Card id="delete-app">
      <MDBox
        pr={3}
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        <MDBox p={3} lineHeight={1}>
          <MDBox mb={1}>
            <MDTypography variant="h5">Delete App</MDTypography>
          </MDBox>
          <MDTypography variant="button" color="text">
            Once you delete your app, there is no going back. Please be certain.
          </MDTypography>
        </MDBox>
        <MDBox display="flex" flexDirection={{ xs: 'column', sm: 'row' }}>
          <MDButton variant="outlined" color="secondary">
            deactivate
          </MDButton>
          <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
            <MDButton
              variant="gradient"
              color="error"
              sx={{ height: '100%' }}
              onClick={hadleDeleteApp}
            >
              delete App
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  )
}

export default DeleteAccount
