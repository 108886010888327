/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from 'react'

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types'

// @mui material components
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Icon from '@mui/material/Icon'
import FilterListIcon from '@mui/icons-material/FilterList'
// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDAvatar from 'components/MDAvatar'

// Material Dashboard 2 PRO React base styles
import breakpoints from 'assets/theme/base/breakpoints'

// Images
import burceMars from 'assets/images/bruce-mars.jpg'
import backgroundImage from 'assets/images/bg-profile.jpeg'
import Locations from '../../Locations'
import Users from '../../Users'
import Companies from '../../Companies'
import { Button, Menu, MenuItem } from '@mui/material'
function Header({ children }) {
  const [tabsOrientation, setTabsOrientation] = useState('horizontal')
  const [tabValue, setTabValue] = useState(0)
  const [anchorEl, setAnchorEl] = useState(null)
  const [selctedMenu, setselctedMenu] = useState('Companies')
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation('vertical')
        : setTabsOrientation('horizontal')
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener('resize', handleTabsOrientation)

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation()

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleTabsOrientation)
  }, [tabsOrientation])

  const handleSetTabValue = (event, newValue) => setTabValue(newValue)
  const handleClose = (value, tabvalue) => {
    if (value) {
      setselctedMenu(value)
    }
    if (tabvalue || tabvalue == 0) {
      setTabValue(tabvalue)
    }
    setAnchorEl(null)
  }
  const elements = [<Companies />, <Locations />, <Users />]
  return (
    <MDBox position="relative" mb={5}>
      {/* <AppBar position="static">
        <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
          <Tab
            label="Companies"
            icon={
              <Icon fontSize="small" sx={{ mt: -0.25 }}>
                home
              </Icon>
            }
          />
          <Tab
            label="Locations"
            icon={
              <Icon fontSize="small" sx={{ mt: -0.25 }}>
                email
              </Icon>
            }
          />
          <Tab
            label="Users"
            icon={
              <Icon fontSize="small" sx={{ mt: -0.25 }}>
                settings
              </Icon>
            }
          />
        </Tabs>
      </AppBar> */}
      <div>
        <Button
          id="demo-positioned-button"
          aria-controls={open ? 'demo-positioned-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <MDBox display={'flex'} alignItems="center" gap={1}>
            {selctedMenu}
            <FilterListIcon />
          </MDBox>
        </Button>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={() => handleClose(selctedMenu, tabValue)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem onClick={() => handleClose('Companies', 0)}>Companies</MenuItem>
          <MenuItem onClick={() => handleClose('Locations', 1)}>Locations</MenuItem>
          <MenuItem onClick={() => handleClose('Users', 2)}>Users</MenuItem>
        </Menu>
      </div>
      <MDBox m={5}>{elements[tabValue]}</MDBox>
    </MDBox>
  )
}

// Setting default props for the Header
Header.defaultProps = {
  children: '',
}

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
}

export default Header
